import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../components/HomePage.vue'
import WillOfTheImmortalsSeries from '../components/WillOfTheImmortalsSeries.vue'
import WillOfTheImmortalsCharacterSheets from '../components/WillOfTheImmortalsCharacterSheets.vue'
import PrivateerSeries from '../components/PrivateerSeries.vue'
import AboutMe from '../components/AboutMe.vue'
import SignedCopies from '../components/SignedCopies.vue'
import RoyaltyAverages from '../components/RoyaltyAverages.vue'

const routes = [
  { path: '/', name: 'HomePage', component: HomePage },
  { path: '/will-of-the-immortals', name: 'WillOfTheImmortals', component: WillOfTheImmortalsSeries },
  { path: '/will-of-the-immortals-characters', name: 'WillOfTheImmortalsCharacterSheets', component: WillOfTheImmortalsCharacterSheets },
  { path: '/privateer', name: 'PrivateerSeries', component: PrivateerSeries },
  { path: '/signed-copies/', name: 'SignedCopies', component: SignedCopies },
  { path: '/about', name: 'AboutMe', component: AboutMe },
  { path: '/royalty-dashboard', name: 'RoyaltyAverages', component: RoyaltyAverages }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router