<template>
  <div class="home">

    <!-- Profile Section -->
    <div class="diagonal-section light-section">
      <section class="profile-section">
        <div class="profile-container">
          <img
            class="profile-image"
            src="https://jaykraussauthor.s3.amazonaws.com/jayauthorpicture.jpg"
            alt="Profile Image"
          />
          <div class="profile-text">
            <p>Jay is an animal lover, software developer, traditional blacksmith, modern woodworker, terrible mechanic, avid reader, and author.</p>
            <p>A master of no trades, perhaps, but a consummate dabbler.</p>
            <p>Writing in both Sci-Fi and Fantasy genres.</p>
            <router-link to="/about" class="btn about-link">Learn More About Jay</router-link>
          </div>
        </div>
      </section>
    </div>

    <!-- Series Section -->
    <div class="diagonal-section dark-section">
      <section class="series-section">
        <div class="series-container">
          <div class="series-box">
            <router-link to="/will-of-the-immortals" class="btn series-link">
              <img
                src="https://jaykraussauthor.s3.us-east-1.amazonaws.com/NEW+Steel+Foundations+Backgroun.png"
                alt="Will of the Immortals"
                class="series-image"
              />
            </router-link>
            <h2>Will of the Immortals</h2>
            <p>An epic journey of cultivation and willpower.</p>
          </div>

          <div class="series-box">
            <router-link to="/privateer" class="btn series-link">
              <img
                src="https://jaykraussauthor.s3.amazonaws.com/privateerseriesimage.png"
                alt="Privateer"
                class="series-image"
              />
            </router-link>
            <h2>Privateer</h2>
            <p>Chart a path through the stars.</p>
          </div>
        </div>
      </section>
    </div>

    <!-- Socials Section -->
    <div class="diagonal-section light-section">
      <section class="socials-section">
        <h2>Connect with me</h2>
        <div class="socials-links">
          <a class="btn link-button" href="https://www.facebook.com/profile.php?id=61567395804159" target="_blank">Facebook</a>
          <a class="btn link-button" href="https://www.amazon.com/stores/Jay-Krauss/author/B0DHLFG6BB" target="_blank">Amazon</a>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
};
</script>

<style scoped>
/* Hero Section */
.hero-section {
  position: relative;
  width: 100%;
  height: 60vh;
  max-height: 300px;
  background-color: #1f1f1f;
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
}

.overlay-text {
  position: absolute;
  color: white;
  text-align: center;
}

.dark-section {
  min-height: 600px;
}

/* Profile Section */
.profile-section {
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 350px;
}

.profile-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 800px;
  text-align: center;
}

.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

/* Series Section */
.series-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2rem;
}

.series-box {
  max-width: 300px;
  text-align: center;
  color: white;

  h2 {
    color: white;
    margin-top: 10px;
  }
}

.series-image {
  width: 225px;
  height: 350px;
  object-fit: cover;
}

/* Socials Section */
.socials-section {
  text-align: center;
}

.socials-links {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .series-container {
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .profile-container {
    flex-direction: column;
  }
}
</style>
