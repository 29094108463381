<template>
  <div class="series-page">
    <!-- Series Overview Section -->
    <div class="diagonal-section light-section">
      <section class="series-overview">
        <img class="series-image" src="https://jaykraussauthor.s3.amazonaws.com/privateerseriesimage.png" alt="Series Image" />
        <p>
          The Privateer series explores space adventures, following daring explorers who brave the unknown to uncover ancient secrets and confront powerful enemies.
        </p>
      </section>
    </div>

    <!-- Books Section -->
    <div class="diagonal-section dark-section">
      <section class="books-section">
        <div class="book">
          <img src="https://jaykraussauthor.s3.amazonaws.com/privateercover.png" alt="Privateer: Prologue" class="book-cover" />
          <div class="book-info">
            <h2>Privateer: Prologue</h2>
            <p>The entry point into the upcoming Privateer series, Privateer: Prologue is a fast-paced military science fiction novel meant to immerse the reader into the Privateer universe.</p>
            <p>Follow the path of a man left for dead in the vacuum of space, clad in billions of dollars of cutting edge military technology as he tries to sort out his past and figure out his future.
            Armed with only a rifle, an AI co-pilot, and near total amnesia, he's determined to make a difference.. or die trying.</p>
            <p>Privateer is military science fiction written with real-life technology in mind, albeit set centuries into the future.
            It is meant to be a primer for the upcoming Privateer series, which are intended to be longer-form novels.</p>
            <p>The first book in the series is well underway, and updates will be posted on the Privateer Facebook page as they become available.</p>
            <a class="purchase-link" href="https://www.amazon.com/Privateer-Prologue-Jay-Krauss-ebook/dp/B0BTY79WKC" target="_blank">Purchase on Amazon</a>
          </div>
        </div>

        <div class="book">
          <img src="https://jaykraussauthor.s3.amazonaws.com/privateerseriesimage2.png" alt="Titan's Resurrection" class="book-cover" />
          <div class="book-info">
            <h2>Titan's Resurrection</h2>
            <p>The second book in the series follows the crew’s quest to resurrect ancient technology and combat powerful threats.</p>
            <!-- <a href="#" target="_blank">Purchase on Amazon</a> -->
             <p>Coming soon!</p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<style scoped>
.series-overview {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 10px;
  img {
    width: 300px;
    height: 300px;
    border-radius: 10px;
  }
}

.hero-section {
  margin-top: 0;
  max-height: 100px;
}

.dark-section {
  background-color: #333;
  color: white;
  min-height: 400px;
}

@media (max-width: 768px) {
  .books-section {
    padding-top: 150px;
    padding-bottom: 100px;
  }

  .book {
    flex-direction: column;
    text-align: center;
  }

  .book-cover {
    width: 80%;
    margin-bottom: 1rem;
  }
}
</style>