<template>
  <div :id="componentId"></div>
</template>

<script>
/* global ShopifyBuy */ // Tell ESLint that ShopifyBuy is a global variable
export default {
  name: "ShopifyButton",
  props: {
    productId: {
      type: String,
      required: true,
    },
    componentId: {
      type: String,
      required: true,
    },
    domain: {
      type: String,
      required: true,
      default: "your-shopify-domain.myshopify.com",
    },
    storefrontAccessToken: {
      type: String,
      required: true,
      default: "your-storefront-access-token",
    },
  },
  mounted() {
    this.loadShopifyScript();
  },
  methods: {
    loadShopifyScript() {
      const scriptURL =
        "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";
      if (window.ShopifyBuy) {
        if (window.ShopifyBuy.UI) {
          this.initializeShopifyBuy();
        } else {
          this.loadScript(scriptURL, this.initializeShopifyBuy);
        }
      } else {
        this.loadScript(scriptURL, this.initializeShopifyBuy);
      }
    },
    loadScript(url, callback) {
      const script = document.createElement("script");
      script.async = true;
      script.src = url;
      document.head.appendChild(script);
      script.onload = callback;
    },
    initializeShopifyBuy() {
      const client = ShopifyBuy.buildClient({
        domain: this.domain,
        storefrontAccessToken: this.storefrontAccessToken,
      });
      ShopifyBuy.UI.onReady(client).then((ui) => {
        ui.createComponent("product", {
          id: this.productId,
          node: document.getElementById(this.componentId),
          moneyFormat: "%24%7B%7Bamount%7D%7D",
          options: {
            product: {
              styles: {
                product: {
                  "@media (min-width: 601px)": {
                    "max-width": "calc(25% - 20px)",
                    "margin-left": "20px",
                    "margin-bottom": "50px",
                  },
                },
                button: {
                  "font-family": "Oxygen, sans-serif",
                  "font-size": "16px",
                  "padding-top": "16px",
                  "padding-bottom": "16px",
                  ":hover": {
                    "background-color": "#0950c5",
                  },
                  "background-color": "#052f74",
                  ":focus": {
                    "background-color": "#0950c5",
                  },
                  "border-radius": "8px",
                },
                quantityInput: {
                  "font-size": "16px",
                  "padding-top": "16px",
                  "padding-bottom": "16px",
                },
              },
              text: {
                button: "Add to cart",
              },
              googleFonts: ["Oxygen"],
            },
            cart: {
              styles: {
                button: {
                  "font-family": "Oxygen, sans-serif",
                  "font-size": "16px",
                  "padding-top": "16px",
                  "padding-bottom": "16px",
                  ":hover": {
                    "background-color": "#0950c5",
                  },
                  "background-color": "#052f74",
                  ":focus": {
                    "background-color": "#0950c5",
                  },
                  "border-radius": "8px",
                },
              },
              text: {
                total: "Subtotal",
                button: "Checkout",
              },
              googleFonts: ["Oxygen"],
            },
            toggle: {
              styles: {
                toggle: {
                  "font-family": "Oxygen, sans-serif",
                  "background-color": "#052f74",
                  ":hover": {
                    "background-color": "#0950c5",
                  },
                  ":focus": {
                    "background-color": "#0950c5",
                  },
                },
                count: {
                  "font-size": "16px",
                },
              },
              googleFonts: ["Oxygen"],
            },
          },
        });
      });
    },
  },
};
</script>
