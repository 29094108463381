<template>
    <div v-if="authenticated" class="daily-totals">
      <h1>2025 Earnings Dashboard</h1>
  
      <!-- Current Progress Section -->
      <h2>Current Progress (Up to {{ currentDate }} {{ currentTime }})</h2>
      <p><strong>Time Elapsed:</strong> {{ elapsedHours }} hours and {{ elapsedMinutes }} minutes</p>
      <p><strong>Royalties Projected Current:</strong> ${{ projectedRoyalties.toFixed(2) }}</p>
      <p><strong>Orders Projected Current:</strong> {{ projectedOrders.toFixed(0) }}</p>
      <p><strong>KU Pages Read Projected Current:</strong> {{ projectedKUPages.toLocaleString() }}</p>
  
      <hr />
  
      <!-- Input Section for Current Numbers -->
      <h2>Compare Current Numbers</h2>
      <div class="input-section">
        <label>
          <strong>Current Royalties ($):</strong>
          <input v-model.number="inputRoyalties" type="number" placeholder="Enter current royalties" />
        </label>
        <label>
          <strong>Current Orders:</strong>
          <input v-model.number="inputOrders" type="number" placeholder="Enter current orders" />
        </label>
        <label>
          <strong>Current KU Pages:</strong>
          <input v-model.number="inputKUPages" type="number" placeholder="Enter current KU pages" />
        </label>
        <!-- <button @click="submitData">Submit Current Data</button> -->
      </div>
  
      <div class="comparison-results">
        <h3>Comparison Results:</h3>
        <p>
          <strong>Royalties:</strong>
          ${{ inputRoyalties.toFixed(2) }}
          ({{ compareValues(inputRoyalties, projectedRoyalties) }} by ${{ calculateDifference(inputRoyalties, projectedRoyalties).toFixed(2) }} /
          {{ calculatePercentageDifference(inputRoyalties, projectedRoyalties).toFixed(2) }}%)
        </p>
        <p>
          <strong>Orders:</strong>
          {{ inputOrders }}
          ({{ compareValues(inputOrders, projectedOrders) }} by {{ calculateDifference(inputOrders, projectedOrders).toFixed(0) }} /
          {{ calculatePercentageDifference(inputOrders, projectedOrders).toFixed(2) }}%)
        </p>
        <p>
          <strong>KU Pages:</strong>
          {{ inputKUPages.toLocaleString() }}
          ({{ compareValues(inputKUPages, projectedKUPages) }} by
          {{ calculateDifference(inputKUPages, projectedKUPages).toLocaleString() }} /
          {{ calculatePercentageDifference(inputKUPages, projectedKUPages).toFixed(2) }}%)
        </p>
      </div>
  
      <hr />
  
      <!-- 2025 Totals Table -->
      <h2>2025 Monthly Projections</h2>
      <table>
        <thead>
          <tr>
            <th>Month</th>
            <th>Royalties</th>
            <th>Orders</th>
            <th>KU Pages Read</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(data, month) in monthlyData" :key="month">
            <td>{{ month }}</td>
            <td>${{ (data.dailyRoyalties * daysInMonth(month)).toFixed(2) }}</td>
            <td>{{ Math.round(data.dailyOrders * daysInMonth(month)) }}</td>
            <td>{{ (data.dailyKUPages * daysInMonth(month)).toLocaleString() }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  
    <div v-else class="password-protect">
      <h2>Enter Password</h2>
      <input v-model="passwordInput" type="password" placeholder="Password" />
      <button @click="checkPassword">Submit</button>
      <p v-if="incorrectPassword" class="error">Incorrect Password. Please try again.</p>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        // Authentication
        passwordInput: "",
        authenticated: false,
        incorrectPassword: false,
        correctPassword: "Armorh4c$",
  
        // Monthly data
        monthlyData: {
          January: { dailyRoyalties: 1726, hourlyRoyalties: 85.58, dailyOrders: 129, hourlyOrders: 5.38, dailyKUPages: 417290, hourlyKUPages: 17387 },
          February: { dailyRoyalties: 1700, hourlyRoyalties: 70.83, dailyOrders: 97, hourlyOrders: 4.04, dailyKUPages: 302079, hourlyKUPages: 12586 },
          March: { dailyRoyalties: 3548, hourlyRoyalties: 147.83, dailyOrders: 204, hourlyOrders: 8.50, dailyKUPages: 630823, hourlyKUPages: 26284 },
          April: { dailyRoyalties: 3253, hourlyRoyalties: 135.54, dailyOrders: 186, hourlyOrders: 7.75, dailyKUPages: 577778, hourlyKUPages: 24074 },
          May: { dailyRoyalties: 2000, hourlyRoyalties: 83.33, dailyOrders: 114, hourlyOrders: 4.75, dailyKUPages: 355556, hourlyKUPages: 14815 },
          June: { dailyRoyalties: 2000, hourlyRoyalties: 83.33, dailyOrders: 115, hourlyOrders: 4.79, dailyKUPages: 355556, hourlyKUPages: 14815 },
          July: { dailyRoyalties: 3280, hourlyRoyalties: 136.67, dailyOrders: 188, hourlyOrders: 7.83, dailyKUPages: 583513, hourlyKUPages: 24313 },
          August: { dailyRoyalties: 2200, hourlyRoyalties: 91.67, dailyOrders: 126, hourlyOrders: 5.25, dailyKUPages: 391399, hourlyKUPages: 16308 },
          September: { dailyRoyalties: 2200, hourlyRoyalties: 91.67, dailyOrders: 126, hourlyOrders: 5.25, dailyKUPages: 391111, hourlyKUPages: 16296 },
          October: { dailyRoyalties: 3574, hourlyRoyalties: 148.92, dailyOrders: 205, hourlyOrders: 8.54, dailyKUPages: 636558, hourlyKUPages: 26523 },
          November: { dailyRoyalties: 2400, hourlyRoyalties: 100.00, dailyOrders: 138, hourlyOrders: 5.75, dailyKUPages: 426667, hourlyKUPages: 17778 },
          December: { dailyRoyalties: 2694, hourlyRoyalties: 112.25, dailyOrders: 154, hourlyOrders: 6.42, dailyKUPages: 478857, hourlyKUPages: 19952 },
        },
  
        // User input
        inputRoyalties: 0,
        inputOrders: 0,
        inputKUPages: 0,
  
        // Time-based data
        currentMonth: new Date().toLocaleString("default", { month: "long" }),
        startOfDay: new Date().setHours(0, 0, 0, 0),
        now: new Date(),
      };
    },
    computed: {
      currentDate() {
        return this.now.toLocaleDateString();
      },
      currentTime() {
        return this.now.toLocaleTimeString();
      },
      elapsedHours() {
        return Math.floor((this.now - this.startOfDay) / (1000 * 60 * 60));
      },
      elapsedMinutes() {
        return Math.floor(((this.now - this.startOfDay) % (1000 * 60 * 60)) / (1000 * 60));
      },
      dailyData() {
        return this.monthlyData[this.currentMonth];
      },
      projectedRoyalties() {
        return this.elapsedHours * this.dailyData.hourlyRoyalties + (this.elapsedMinutes / 60) * this.dailyData.hourlyRoyalties;
      },
      projectedOrders() {
        return this.elapsedHours * this.dailyData.hourlyOrders + (this.elapsedMinutes / 60) * this.dailyData.hourlyOrders;
      },
      projectedKUPages() {
        return this.elapsedHours * this.dailyData.hourlyKUPages + (this.elapsedMinutes / 60) * this.dailyData.hourlyKUPages;
      },
    },
    methods: {
      checkPassword() {
        if (this.passwordInput === this.correctPassword) {
          this.authenticated = true;
        } else {
          this.incorrectPassword = true;
        }
      },
      compareValues(current, projected) {
        if (current > projected) return "Above Average";
        if (current < projected) return "Below Average";
        return "On Track";
      },
      calculateDifference(current, projected) {
        return current - projected;
      },
      calculatePercentageDifference(current, projected) {
        return ((current - projected) / projected) * 100;
      },
      daysInMonth(month) {
        const monthIndex = Object.keys(this.monthlyData).indexOf(month);
        const year = new Date().getFullYear();
        return new Date(year, monthIndex + 1, 0).getDate();
      },
      async submitData() {
        try {
          const data = {
            operation: "store",
            month: this.currentMonth,
            hour: this.elapsedHours,
            royalties: this.inputRoyalties,
            orders: this.inputOrders,
            kuPages: this.inputKUPages,
          };
  
          // Use your Lambda Function URL
          const response = await axios.post("https://xs5brzh7s2cfrdk2hctpfazhoy0wvvif.lambda-url.us-east-1.on.aws/", data);
  
          console.log("Data submitted successfully:", response.data);
  
          // Clear input fields
          this.inputRoyalties = 0;
          this.inputOrders = 0;
          this.inputKUPages = 0;
  
          // Show success message
          alert("Data submitted successfully!");
        } catch (error) {
          console.error("Error submitting data:", error);
  
          // Show error message
          alert("Failed to submit data. Please try again.");
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .daily-totals,
  .password-protect {
    font-family: Arial, sans-serif;
    max-width: 700px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .input-section label {
    display: block;
    margin: 10px 0;
  }
  
  button {
    margin: 10px 0;
    padding: 10px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  th,
  td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
  }
  
  .error {
    color: red;
  }
  
  .comparison-results {
    margin-top: 20px;
  }
  </style>
  